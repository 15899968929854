import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Guiches = Loadable(lazy(() => import('./Guiches')));
const Guiche = Loadable(lazy(() => import('./Guiche')));

const guichesRoutes = [
  { path: '/guiches', element: <Guiches /> },
  { path: '/guiche', element: <Guiche /> },
  { path: '/guiche/:id', element: <Guiche /> }
];

export default guichesRoutes;
